import React from 'react'
import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Link } from 'gatsby'
import { IndexQueryQuery, PostByPathQuery } from '../../../types/graphql-types'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import Video from 'components/video'
import BackgroundImage from 'gatsby-background-image'
import OGImage from 'images/happy-young-man-smiling-against-white-background.jpg'
import Logo from 'images/logo.png'
import { Helmet } from 'react-helmet'
import BtnOutlined from 'components/button/outlined-button'
import TelLinkSimple from 'components/tel-link-simple'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const RestorativeDentistryPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const pd_cta_background = data.pd_cta_background?.childImageSharp?.fluid
  const service_restorative_1 = data.service_restorative_1?.childImageSharp?.fluid
  const service_restorative_2 = data.service_restorative_2?.childImageSharp?.fluid

  service_restorative_1
  


  return (
    <Layout location={location}>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>Restorative Dentistry - The Dentists At Gateway Crossing</title>
      <meta name="description" content="We offer many types of restorative dentistry services to help you get that perfect smile back to the way it was. Browse the page below to learn more. It's" />
      <meta name="robots" content="index, follow, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content="Restorative Dentistry - The Dentists At Gateway Crossing" />
      <meta property="og:description" content="We offer many types of restorative dentistry services to help you get that perfect smile back to the way it was. Browse the page below to learn more. It's" />
      <meta property="og:url" content="https://thedentistsatgc.com/services/restorative-dentistry/" />
      <meta property="og:site_name" content="The Dentists At Gateway Crossing" />
      <meta property="article:publisher" content="https://www.facebook.com/thedentistsatgc/" />
      <meta property="og:updated_time" content="2021-02-05T05:10:25+00:00" />
      <meta property="og:image" content={OGImage} />
      <meta property="og:image:secure_url" content={OGImage} />
      <meta property="og:image:width" content="1333" />
      <meta property="og:image:height" content="2000" />
      <meta property="og:image:alt" content="Happy young man smiling against white background" />
      <meta property="og:image:type" content="image/jpeg" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Restorative Dentistry - The Dentists At Gateway Crossing" />
      <meta name="twitter:description" content="We offer many types of restorative dentistry services to help you get that perfect smile back to the way it was. Browse the page below to learn more. It's" />
      <meta name="twitter:image" content={OGImage} />
      <meta name="google-site-verification" content="khYQGQ-T8YWkXa0QyKr6e2kNlFB3l0ZiFRA89VzVbaM" />
      <meta name="google-site-verification" content="Oll6PjpMRzNx1KbgKilxS6xPddvSbF2lDOa2rd7VkxA" />
      <meta name="msapplication-TileImage" content={Logo} />
      <script async src='https://tag.simpli.fi/sifitag/195cd770-bf42-013a-5143-0cc47a8ffaac'></script>
    </Helmet>
      <BackgroundImage
        Tag="section"
        className="hero-section-bg"
        fluid={hero_background}
        backgroundColor={`#e9f4f8`}
      >
        <div className ="col-lg-12" id ="color-overlay">
        </div>
        <div id="hero-section">
          <Container>
            <div className="col-lg-12 text-center">
              <p className="hero-text">
                RESTORATIVE DENTAL SERVICES MCCORDSVILLE
              </p>
              <h1 className="text-light">
                Restorative Dentistry
              </h1>
            </div>
            <div className="col-lg-12 text-center">
              <p className="hero-text text-light">We offer many types of restorative dentistry services to help you get that perfect smile back to the way it was. Browse the page below to learn more. It’s easy to schedule your appointment with us. </p>
              <p className="hero-text text-light">Just click “schedule now” as soon as you’re ready.</p>
            </div>
            <div className="col-lg-12 text-center">
              <a href="#pd-section-3"><button className="btn-rounded"> Learn More </button></a>
              <a href="https://flexbook.me/tdagc/website" target="_blank"><button className="btn-rounded"> Schedule Now </button></a>
            </div>
          </Container>
          </div>
      </BackgroundImage>
      <section id ="pd-section-1" className="page-section text-center">
          <Container>
            <div className="col-lg-12">
              <p className ="section-1-text">RESTORATIVE DENTAL CARE</p>
              <h2 className ="section-2-header">What Are The Benefits Of Restorative Dentistry?</h2>
              <p>Sometimes your smile shows can become damaged. Whether it is from lack of prevention or something out of your control we can help you get back to normal. As you age teeth start to wear and old dental work can fail. Your teeth and your bit can even start to move adversely. Typically, this is where restorative dentistry is the solution.</p>
            </div>
          </Container>
          <Container>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-bolt" aria-hidden="true"></i>
                <h3 className ="section-1-header">Better Pay & Benefits</h3>
                <p>Your appearance is everything and so is your smile. Having a healthy mouth full of strong white teeth leads to more opportunity and higher pay.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-diamond" aria-hidden="true"></i>
                <h3 className ="section-1-header">Great First Impressions</h3>
                <p>The first thing a person sees when they look at a smiling face is teeth! If your teeth are less than attractive, then you're sending a bad first impression.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-bookmark-o" aria-hidden="true"></i>
                <h3 className ="section-1-header">Boosts Your Self Esteem</h3>
                <p>You know what it feels like to wear a brand new pair of shoes. Having a great smile will give you a similar feeling.</p>
              </div>
            </div>
          </Container>
          <Container>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-bolt" aria-hidden="true"></i>
                <h3 className ="section-1-header">Improves Overall Dental Health</h3>
                <p>When you put time and money into your teeth, you will take better care of them. Maintaining a great smile is just as important as restoring one.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-diamond" aria-hidden="true"></i>
                <h3 className ="section-1-header">Smile More & Live Longer</h3>
                <p>Studies have shown that people who smile live longer happier lives. Are your teeth keeping you from smiling?</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-bookmark-o" aria-hidden="true"></i>
                <h3 className ="section-1-header">High Trust & Productivity</h3>
                <p>A smiling person is considered more trustworthy than a person who doesn't. If you're worrying about your teeth then you're not focusing on the important things in life.</p>
              </div>
            </div>
          </Container>
          <Container>
            <div className="col-lg-6">
              <Img fluid={service_restorative_1} alt="Young Man Showing His Beautiful Smile" />
            </div>
            <div className="col-lg-6">
              <Img fluid={service_restorative_2} alt="A Young Lady Wearing  A Black Hat and Eyeglasses" />
            </div>
          </Container>
        </section>
        <BackgroundImage
          Tag="section"
          className="pdsection2-bg"
          fluid={pd_cta_background}
          backgroundColor={`#e9f4f8`}
          alt="The Dentists at Gateway Crossing"
        >
          <div className ="col-lg-12" id ="color-overlay">
          </div>
          <section id="pdsection-2" className="page-section">
            <Container>
              <div className="col-lg-6">
                <p className="hero-text">
                RESTORE YOUR SMILE!
                </p>
                <h3 className="text-light">
                Don't miss your chance to live life with the smile you've always wanted!
                </h3>
                <p className="hero-text text-light">
                Stop delaying your life. You’re missing out on so many opportunities by not fixing your teeth! Call TODAY!
                </p>
                <BtnOutlined url="tel:3176439434" class="btn-rounded" label="(317) 643-9434" />
              </div>
            </Container>
            </section>
          </BackgroundImage>
          <section id ="pd-section-3" className="page-section">
          <Container>
            <div className="col-lg-12 text-center">
              <p className ="section-1-text">RESTORATIVE DENTAL DETAILS</p>
              <h2 className ="section-3-header">What is Restorative Dentistry ?</h2>
              <p>Restorative dentistry is the treatment of oral health issues that arise from oral disease over time or through physical damage to your teeth. Restorative dentistry is used to return function and often to improve the aesthetics of your smile.</p>
            </div>
          </Container>
          <Container>
            <div className="col-lg-12 text-left">
              <div className="columns">
                <h3 className ="section-3-header">Is Restorative Dentistry Right For You?</h3>
                <p>You have something wrong with your teeth and you want to get it fixed. However, you might be on the fence about restoring your smile. Maybe you’re wondering if you really need to see a dentist to fix your teeth or if you can get by without it. If you’re looking for answers there is a good chance you are in need of a consultation. </p>
                <p>People often come to us with cracked, chipped, and missing teeth. Sometimes they come to us for help with old dental work that has started to fail or they just want to get rid of those old metal fillings and replace them with tooth colored composite fillings. All of these issues can make life harder. Imagine what it feels like to have your smile back again. No missing teeth, no cracks, and no worries about your dental health. If you’re suffering from any of these problems, now is a good time to give us a call. The health of your mouth is directly related to the quality of your life.</p>
                <p>Correcting the issues you’re experiencing is easy. Dr. Vogt is highly trained as a restorative dentist. He can help you improve your dental health and makes it easier to talk, chew, and swallow. All you have to do is contact our staff and we can help you restore your smile so you can get back to living life.</p>
                <p>If you’ve been thinking about restoring your smile, don’t waste another day. There is no reason to miss out on the things you love when your smile is so easy to fix!</p>
              </div>
            </div>
            <div className="col-lg-12 text-left">
              <div className="columns">
                <h3 className ="section-3-header">Our Restorative Dentistry Services List</h3>
                <p>Our restorative dentistry service incorporates tooth color cleaning, crowns, bridges, dentures, and partials.</p>
                <h4 className ="section-3-header">Click the "+" Below To Expand Any Service</h4>
                <div className="accordion">
                  <details open>
                    <summary aria-describedby="Check-ups and Teeth Cleaning:">Crowns and Bridges:</summary>
                    <div className="accordion-content">
                      <p>Are you thinking of replacing a missing tooth? With our dental crown and bridges therapy, you’re covered. A bridge can replace about one to three missing teeth in a row given that some teeth still exist on either side of the gap. Dr. Vogt will fit you for a custom-made bridge, and after that, you’ll return to our office to have the new piece placed.</p>
                      <p>We’ll firmly attach the bridge and use crowns to bond it to the adjacent teeth. The whole process is safe, easy, and it will help to return confidence to your smile.</p>
                      <p></p>
                      <p>Alternatively, we have single crowns which can be used to treat dental injuries, severe tooth decay and more.</p>
                    </div>
                  </details>
                  <details>
                    <summary aria-describedby="Oral Cancer Screening:">Dentures and Partials:</summary>
                    <div className="accordion-content">
                      <p>Alternatively you can come to Dr. Vogt to receive well-fitted dentures and partials. Dental prosthetics are detachable and allow you to easily clean and maintain your new teeth. While a partial can replace about one to three teeth in a row, full dentures can replace all the teeth along the upper and lower gums.</p>
                    </div>
                  </details>
                  <details>
                    <summary aria-describedby="Dental Sealants:">Tooth-Colored Fillings:</summary>
                    <div className="accordion-content">
                      <p>When it comes to treating cavities in patients of all ages, tooth-colored fillings are preferred. They’re made from high-quality composite resin which can be shaded to create the perfect match for your normal and natural enamel. Once the filling is in place, it becomes challenging to differentiate it from the rest of your natural smile.</p>
                      <p>Given that composite resin is a flexible and conservative material, Dr. Vogt will preserve more of your remaining healthy tooth structure when placing the filling. Overall, Tooth-colored fillings are very comfortable because they lack metals and are far less sensitive to external forces like temperature and physical objects.</p>
                    </div>
                  </details>
                </div>
              </div>
            </div>
            <div className="col-lg-12 text-left">
              <div className="columns">
                <h3 className ="section-3-header">Are Restorative Dental Procedures Covered By My Insurance?</h3>
                <p>Every patient’s situation is different. We would love to discuss payment options with you. Please call our office at <TelLinkSimple />.</p>
              </div>
            </div>
          </Container>
        </section>
    </Layout>
  )
}

export default RestorativeDentistryPage

export const query = graphql`
  query RestorativeDentistryPageQuery {
    hero: file(name: { eq: "hero-image" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_background: file(name: { eq: "about-us-hero" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    pd_cta_background: file(name: { eq: "placeholder" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    service_restorative_1: file(name: { eq: "restorative_1" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }, 
    service_restorative_2: file(name: { eq: "restorative_2" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
        
      }
    }
  }
`
